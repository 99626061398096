/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ApplicationScope = "APPLIANCE" | "GLOBAL" | "%future added value";
export type UpdateApplicationInput = {
    application: ApplicationInput;
    applicationId: string;
};
export type ApplicationInput = {
    description?: string | null;
    displayName: string;
    protocol: string;
    requiredInterfaces: Array<AstarteInterfaceInput>;
    scope: ApplicationScope;
    slug: string;
    sourceUrl: string;
};
export type AstarteInterfaceInput = {
    majorVersion: number;
    minorVersion: number;
    name: string;
};
export type Application_updateApplication_MutationVariables = {
    input: UpdateApplicationInput;
};
export type Application_updateApplication_MutationResponse = {
    readonly updateApplication: {
        readonly application: {
            readonly id: string;
            readonly protocol: string;
            readonly slug: string;
            readonly displayName: string;
            readonly description: string | null;
            readonly sourceUrl: string;
            readonly scope: ApplicationScope;
            readonly requiredInterfaces: ReadonlyArray<{
                readonly name: string;
                readonly majorVersion: number;
                readonly minorVersion: number;
            }>;
        };
    } | null;
};
export type Application_updateApplication_Mutation = {
    readonly response: Application_updateApplication_MutationResponse;
    readonly variables: Application_updateApplication_MutationVariables;
};



/*
mutation Application_updateApplication_Mutation(
  $input: UpdateApplicationInput!
) {
  updateApplication(input: $input) {
    application {
      id
      protocol
      slug
      displayName
      description
      sourceUrl
      scope
      requiredInterfaces {
        name
        majorVersion
        minorVersion
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateApplicationPayload",
    "kind": "LinkedField",
    "name": "updateApplication",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Application",
        "kind": "LinkedField",
        "name": "application",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "protocol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sourceUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scope",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AstarteInterface",
            "kind": "LinkedField",
            "name": "requiredInterfaces",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "majorVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minorVersion",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Application_updateApplication_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Application_updateApplication_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "30b3763f987aaad59d02e2999484b273",
    "id": null,
    "metadata": {},
    "name": "Application_updateApplication_Mutation",
    "operationKind": "mutation",
    "text": "mutation Application_updateApplication_Mutation(\n  $input: UpdateApplicationInput!\n) {\n  updateApplication(input: $input) {\n    application {\n      id\n      protocol\n      slug\n      displayName\n      description\n      sourceUrl\n      scope\n      requiredInterfaces {\n        name\n        majorVersion\n        minorVersion\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '95b72e7daf3cdec79adbe2d017bdf368';
export default node;

import React from "react";
import Button from "react-bootstrap/Button";

import Dropdown from "components/Dropdown";
import Icon from "components/Icon";

interface Props {
  alignRight?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const OptionsMenu = ({ alignRight, children, className = "" }: Props) => (
  <Dropdown
    alignRight={alignRight}
    className={className}
    toggle={
      <Button variant="link">
        <Icon icon="moreOptionsMenu" />
      </Button>
    }
  >
    {children}
  </Dropdown>
);

OptionsMenu.Divider = Dropdown.Divider;
OptionsMenu.Item = Dropdown.Item;

export default OptionsMenu;

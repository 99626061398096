import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReCAPTCHA from "react-google-recaptcha";

import type { Register_getTermsAndConditions_Query } from "api/__generated__/Register_getTermsAndConditions_Query.graphql";
import type { Register_registerAdmin_Mutation } from "api/__generated__/Register_registerAdmin_Mutation.graphql";
import type { Register_login_Mutation } from "api/__generated__/Register_login_Mutation.graphql";
import { useAuth } from "contexts/Auth";
import { useConsoleConfig } from "contexts/ConsoleConfig";
import { Link, Route, useNavigate } from "Navigation";
import AuthPage from "components/AuthPage";
import ConfirmModal from "components/ConfirmModal";
import InputPassword from "components/InputPassword";
import Spinner from "components/Spinner";

const GET_TERMS_AND_CONDITIONS_QUERY = graphql`
  query Register_getTermsAndConditions_Query {
    latestTermsAndConditions {
      id
      text
    }
  }
`;

const LOGIN_MUTATION = graphql`
  mutation Register_login_Mutation($input: LoginInput!) {
    login(input: $input) {
      token
    }
  }
`;

const REGISTER_ADMIN_MUTATION = graphql`
  mutation Register_registerAdmin_Mutation($input: RegisterAdminInput!) {
    registerAdmin(input: $input) {
      admin {
        name
        email
      }
    }
  }
`;

const privacyPolicy = "Yadda yadda lengthy legalese text"; // TODO: Add a privacy policy

interface FormData {
  userName: string;
  userEmail: string;
  userPassword: string;
  acceptPrivacyPolicy: boolean;
  acceptTermsAndConditions: boolean;
}

const initialFormData: FormData = {
  userName: "",
  userEmail: "",
  userPassword: "",
  acceptPrivacyPolicy: false,
  acceptTermsAndConditions: false,
};

interface RegisterContentProps {
  getTermsAndConditionsQuery: PreloadedQuery<Register_getTermsAndConditions_Query>;
}

const RegisterContent = ({
  getTermsAndConditionsQuery,
}: RegisterContentProps) => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [validated, setValidated] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(
    null
  );
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    useState(false);
  const intl = useIntl();
  const navigate = useNavigate();
  const user = useAuth();
  const consoleConfig = useConsoleConfig();
  const termsAndConditionsData = usePreloadedQuery(
    GET_TERMS_AND_CONDITIONS_QUERY,
    getTermsAndConditionsQuery
  );

  const termsAndConditions = useMemo(
    () => ({ ...termsAndConditionsData.latestTermsAndConditions }),
    [termsAndConditionsData.latestTermsAndConditions]
  );

  const [registerAdmin, isRegisteringAdmin] =
    useMutation<Register_registerAdmin_Mutation>(REGISTER_ADMIN_MUTATION);

  const [login, isLoggingIn] =
    useMutation<Register_login_Mutation>(LOGIN_MUTATION);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        return setValidated(true);
      }
      const admin = {
        name: formData.userName,
        credential: {
          email: formData.userEmail,
          password: formData.userPassword,
        },
        acceptPrivacyPolicy: formData.acceptPrivacyPolicy,
        acceptTermsAndConditions: formData.acceptTermsAndConditions,
        acceptedTermsAndConditionsId: termsAndConditions.id,
      };
      registerAdmin({
        variables: { input: { admin, recaptchaResponse } },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
          login({
            variables: {
              input: {
                email: formData.userEmail,
                password: formData.userPassword,
              },
            },
            onCompleted(data, errors) {
              if (errors) {
                const errorFeedback = errors
                  .map((error) => error.message)
                  .join(". \n");
                return setErrorFeedback(errorFeedback);
              }
              const authToken = data.login?.token || null;
              if (authToken) {
                user.setAuthToken(authToken);
              } else {
                navigate({ route: Route.login });
              }
            },
            onError(error) {
              navigate({ route: Route.login });
            },
          });
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.Register.form.loadingErrorFeedback"
              defaultMessage="Could not register, please try again."
              description="Feedback for unknown loading error in the Register page"
            />
          );
        },
      });
    },
    [
      formData,
      registerAdmin,
      login,
      user,
      navigate,
      termsAndConditions.id,
      recaptchaResponse,
    ]
  );

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const field = target.id;
      setFormData((data) => ({ ...data, [field]: value }));
    }, []);

  const isRegistering = isRegisteringAdmin || isLoggingIn;
  const showCaptcha = !!consoleConfig.recaptchaSiteKey;
  const isMissingCaptcha = showCaptcha && !recaptchaResponse;
  const canRegister = !isMissingCaptcha && !isRegistering;

  return (
    <>
      <AuthPage>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="userName">
            <Form.Control
              value={formData.userName}
              onChange={handleInputChange}
              required
              placeholder={intl.formatMessage({
                id: "pages.Register.form.userNamePlaceholder",
                defaultMessage: "Name",
                description:
                  "Placeholder for the user name field in the Register page",
              })}
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage
                id="pages.Register.form.invalidUserNameFeedback"
                defaultMessage="Please provide a user name."
                description="Feedback for invalid user name field in the Register page"
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="userEmail">
            <Form.Control
              value={formData.userEmail}
              onChange={handleInputChange}
              required
              type="email"
              placeholder={intl.formatMessage({
                id: "pages.Register.form.userEmailPlaceholder",
                defaultMessage: "Email",
                description:
                  "Placeholder for the user email field in the Register page",
              })}
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage
                id="pages.Register.form.invalidUserEmailFeedback"
                defaultMessage="Please provide a valid user email."
                description="Feedback for invalid user email field in the Register page"
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="userPassword">
            <InputPassword
              value={formData.userPassword}
              onChange={handleInputChange}
              required
              placeholder={intl.formatMessage({
                id: "pages.Register.form.userPasswordPlaceholder",
                defaultMessage: "Password",
                description:
                  "Placeholder for the user password field in the Register page",
              })}
            >
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.Register.form.invalidUserPasswordFeedback"
                  defaultMessage="Please provide a password."
                  description="Feedback for invalid user password field in the Register page"
                />
              </Form.Control.Feedback>
            </InputPassword>
          </Form.Group>
          <Form.Group controlId="acceptPrivacyPolicy">
            <Form.Check
              type="checkbox"
              checked={formData.acceptPrivacyPolicy}
              onChange={handleInputChange}
              label={intl.formatMessage(
                {
                  id: "pages.Register.form.acceptPrivacyPolicyLabel",
                  defaultMessage: "Accept <link>Privacy Policy</link>",
                  description:
                    "Label for the Privacy Policy acceptance field in the Register page",
                },
                {
                  link: (chunks: React.ReactNode) => (
                    <Button
                      variant="link"
                      className="p-0 pl-1"
                      onClick={() => setShowPrivacyPolicyModal(true)}
                    >
                      {chunks}
                    </Button>
                  ),
                }
              )}
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage
                id="pages.Register.form.invalidAcceptPrivacyPolicyFeedback"
                defaultMessage="Please read and accept the Privacy Policy."
                description="Feedback for invalid Privacy Policy acceptance field in the Register page"
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="acceptTermsAndConditions">
            <Form.Check
              type="checkbox"
              checked={formData.acceptTermsAndConditions}
              onChange={handleInputChange}
              label={intl.formatMessage(
                {
                  id: "pages.Register.form.acceptTermsAndConditionsLabel",
                  defaultMessage: "Accept <link>Terms & Conditions</link>",
                  description:
                    "Label for the Terms and Conditions acceptance field in the Register page",
                },
                {
                  link: (chunks: React.ReactNode) => (
                    <Button
                      variant="link"
                      className="p-0 pl-1"
                      onClick={() => setShowTermsAndConditionsModal(true)}
                    >
                      {chunks}
                    </Button>
                  ),
                }
              )}
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage
                id="pages.Register.form.invalidAcceptTermsAndConditionsFeedback"
                defaultMessage="Please read and accept the Terms and Conditions."
                description="Feedback for invalid Terms and Conditions acceptance field in the Register page"
              />
            </Form.Control.Feedback>
          </Form.Group>
          {consoleConfig.recaptchaSiteKey && (
            <div className="d-flex justify-content-center">
              <ReCAPTCHA
                sitekey={consoleConfig.recaptchaSiteKey}
                onChange={setRecaptchaResponse}
              />
            </div>
          )}
          <Button
            variant="primary"
            type="submit"
            className="w-100 mt-3"
            disabled={!canRegister}
          >
            {isRegistering && <Spinner size="sm" className="mr-2" />}
            <FormattedMessage
              id="pages.Register.form.passwordRegisterButton"
              defaultMessage="Register"
              description="Title for the button to login with password in the Register Page"
            />
          </Button>
          <div className="mt-5 text-center">
            <p>
              <FormattedMessage
                id="pages.Register.form.loginLink"
                defaultMessage="Already have an account? <loginLink>Login</loginLink>"
                description="Title for the link to Login in the Register Page"
                values={{
                  loginLink: (chunks: React.ReactNode) => (
                    <Link route={Route.login}>{chunks}</Link>
                  ),
                }}
              />
            </p>
          </div>
        </Form>
      </AuthPage>
      {showPrivacyPolicyModal && (
        <ConfirmModal
          title={
            <FormattedMessage
              id="pages.Register.privacyPolicyModal.title"
              defaultMessage="Privacy Policy"
              description="Title for the modal to show the Privacy Policy in the Register page"
            />
          }
          confirmLabel={
            <FormattedMessage
              id="pages.Register.privacyPolicyModal.confirmButton"
              defaultMessage="OK"
              description="Title for the button to confirm the modal of Privacy Policy in the Register page"
            />
          }
          onConfirm={() => setShowPrivacyPolicyModal(false)}
        >
          <p>{privacyPolicy}</p>
        </ConfirmModal>
      )}
      {showTermsAndConditionsModal && (
        <ConfirmModal
          title={
            <FormattedMessage
              id="pages.Register.termsAndConditionsModal.title"
              defaultMessage="Terms and Conditions"
              description="Title for the modal to show the Terms and Conditions in the Register page"
            />
          }
          confirmLabel={
            <FormattedMessage
              id="pages.Register.termsAndConditionsModal.confirmButton"
              defaultMessage="OK"
              description="Title for the button to confirm the modal of Terms and Conditions in the Register page"
            />
          }
          onConfirm={() => setShowTermsAndConditionsModal(false)}
        >
          <p>{termsAndConditions.text}</p>
        </ConfirmModal>
      )}
    </>
  );
};

const Register = () => {
  const [getTermsAndConditionsQuery, getTermsAndConditions] =
    useQueryLoader<Register_getTermsAndConditions_Query>(
      GET_TERMS_AND_CONDITIONS_QUERY
    );

  useEffect(() => getTermsAndConditions({}), [getTermsAndConditions]);

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.Register.loadingError.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the Register page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.Register.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the Register page"
              />
            </Button>
          </div>
        )}
        onReset={() => getTermsAndConditions({})}
      >
        {getTermsAndConditionsQuery && (
          <RegisterContent
            getTermsAndConditionsQuery={getTermsAndConditionsQuery}
          />
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default Register;

import React from "react";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Navbar from "react-bootstrap/Navbar";
import NavLink from "react-bootstrap/NavLink";

import "./Sidebar.scss";
import { Link, Route, matchPaths } from "Navigation";
import Icon from "components/Icon";
import { useTenant } from "contexts/Tenant";
import type { ParametricRoute } from "Navigation";

type SidebarItemProps = {
  icon?: React.ComponentProps<typeof Icon>["icon"];
  label: JSX.Element;
  activeRoutes?: Route | Route[];
} & ParametricRoute;

const SidebarItem = ({
  icon,
  label,
  activeRoutes,
  ...linkProps
}: SidebarItemProps) => {
  const location = useLocation();
  const matchingRoutes = activeRoutes ? activeRoutes : linkProps.route;
  const isActive = matchPaths(matchingRoutes, location.pathname);
  return (
    <NavLink
      as={Link}
      className={`w-100 d-flex align-items-center ${
        isActive ? "text-light bg-primary" : "text-dark"
      }`}
      {...linkProps}
    >
      {icon && <Icon icon={icon} className="mr-2" />}
      <span className={icon ? "" : "pl-4"}>{label}</span>
    </NavLink>
  );
};

const Sidebar = () => {
  const { tenantId } = useTenant();

  if (!tenantId) {
    return null;
  }

  return (
    <Navbar bg="light" className="sidebar-menu flex-column p-0">
      <SidebarItem
        label={
          <FormattedMessage
            id="components.Sidebar.tenantLabel"
            defaultMessage="Overview"
            description="Label for the tenant menu item in the Sidebar"
          />
        }
        icon="dashboard"
        route={Route.tenantsEdit}
        params={{ tenantId }}
      />
      <SidebarItem
        label={
          <FormattedMessage
            id="components.Sidebar.applications"
            defaultMessage="Applications"
            description="Label for the applications menu item in the Sidebar"
          />
        }
        icon="applications"
        route={Route.applications}
        params={{ tenantId }}
        activeRoutes={[
          Route.applications,
          Route.applicationEdit,
          Route.applicationsNew,
        ]}
      />
    </Navbar>
  );
};

export default Sidebar;

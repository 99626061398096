import React from "react";
import BoostrapDropdown from "react-bootstrap/Dropdown";

import "./Dropdown.scss";

interface Props {
  alignRight?: boolean;
  children?: React.ReactNode;
  className?: string;
  toggle: React.ReactNode;
}

const Dropdown = ({ alignRight, children, className = "", toggle }: Props) => (
  <BoostrapDropdown alignRight={alignRight} className={`Dropdown ${className}`}>
    <BoostrapDropdown.Toggle as="div">{toggle}</BoostrapDropdown.Toggle>
    <BoostrapDropdown.Menu className="shadow border-right-0 border-bottom-0 border-left-0 border-primary rounded-0">
      {children}
    </BoostrapDropdown.Menu>
  </BoostrapDropdown>
);

Dropdown.Divider = BoostrapDropdown.Divider;
Dropdown.Item = BoostrapDropdown.Item;

export default Dropdown;
